.backdrop {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.Modal {
  position: relative;
  width: 90%;
  height: 90%;
  background: #ffffff;
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modal h2 {
  padding: 2rem 0 2rem 0;
}

.Modal p {
  width: 60%;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}

.CarouselNav {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 70%;
}

.Carousel {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.Inner {
  white-space: nowrap;
  transition: transform 0.3s;
  display: flex;
  flex-direction: row;
}

.CarouselItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.Invis {
  visibility: hidden;
}

.CloseButton {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.ModalIcon:hover {
  cursor: pointer;
}

.NavigationDots {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 1rem;
}
