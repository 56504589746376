:root {
  --background-color: #1486c3;
  --section-color: #f1f1f1;
}

/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.SkillsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--section-color);
}

.SkillsTitle {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 100%;
  align-items: center;
  padding-top: 7rem;
  margin-bottom: 10rem;
}

.SkillsTitle span {
  font-size: 3rem;
  font-weight: bold;
}

.SkillRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 30%;
}

.SkillsSmall {
  display: none;
}

#skillnavtrigger {
  position: absolute;
  top: 15rem;
  height: 10px;
  width: 100px;
}

/* x-small devices */
@media screen and (min-width: 100px) and (max-width: 575.98px) {
  .SkillsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: var(--section-color);
  }

  .SkillsTitle {
    padding-top: 4rem;
    margin-bottom: 4rem;
  }

  .SkillRow {
    display: none;
  }

  .SkillsSmall {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .SkillRowSmall {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
  }
}

/* small devices */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .SkillsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: var(--section-color);
  }

  .SkillsTitle {
    padding-top: 4rem;
    margin-bottom: 4rem;
  }

  .SkillRow {
    display: none;
  }

  .SkillsSmall {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .SkillRowSmall {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
  }
}

/* medium devices */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .SkillsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: var(--section-color);
  }

  .SkillsTitle {
    padding-top: 4rem;
    margin-bottom: 4rem;
  }

  .SkillRow {
    display: none;
  }

  .SkillsSmall {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .SkillRowSmall {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
  }
}

/* large devices */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
}

/* x-large devices */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
}
