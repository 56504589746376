:root {
  --navbar-color: #162244;
  --active-color: #eb068b;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5%;
  width: 100%;
  background-color: var(--navbar-color);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  border-bottom: 4px solid var(--active-color);
}
.NavLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  list-style: none;
}

.ResumeLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 1rem;
  list-style: none;
}

.ResumeLink li {
  padding-right: 1.5rem;
}

.NavLinks li {
  padding-left: 1.5rem;
}

.Inactive {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fff;
}

.Active {
  text-decoration: none;
  font-size: 1.5rem;
  color: var(--active-color);
}

.sticky {
  position: fixed;
  top: 0;
}

.MenuButton {
  display: none;
}

.mobilenav {
  display: none;
}

/* x-small devices */
@media screen and (min-width: 100px) and (max-width: 575.98px) {
  .mobilenav {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 7%;
    width: 100%;
    background-color: var(--navbar-color);
    z-index: 100;
    border-bottom: 4px solid var(--active-color);
    transition: all 0.5s ease-in-out;
  }

  .open {
    height: 60%;
  }

  .MenuButtonContainer {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    height: auto;
    padding: 1.3rem 1rem 0 0;
  }

  .nav {
    display: none;
  }

  .sticky {
    position: fixed;
    top: 0;
  }

  .Inactive {
    display: none;
  }
  .NavLinks li {
    display: none;
  }
  .MenuButton {
    display: flex;
  }

  .MenuButton button {
    border: none;
    background: none;
  }

  .MobileLinksContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 3rem 0 1rem 0;
    opacity: 1;
    transition: opacity 1s;
  }

  .Closed {
    height: 0px;
    width: 0px;
    opacity: 0;
  }

  .MobileLinksContainer li {
    list-style: none;
    padding-bottom: 2rem;
  }

  .MobileLinksContainer a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #fff;
  }
}

/* small devices */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .mobilenav {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 7%;
    width: 100%;
    background-color: var(--navbar-color);
    z-index: 100;
    border-bottom: 4px solid var(--active-color);
    transition: all 0.5s ease-in-out;
  }

  .open {
    height: 60%;
  }

  .MenuButtonContainer {
    position: absolute;
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    height: auto;
    padding: 1.3rem 1rem 0 0;
  }

  .nav {
    display: none;
  }

  .sticky {
    position: fixed;
    top: 0;
  }

  .Inactive {
    display: none;
  }
  .NavLinks li {
    display: none;
  }
  .MenuButton {
    display: flex;
  }

  .MenuButton button {
    border: none;
    background: none;
  }

  .MobileLinksContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 3rem 0 1rem 0;
    opacity: 1;
    transition: opacity 1s;
  }

  .Closed {
    height: 0px;
    width: 0px;
    opacity: 0;
  }

  .MobileLinksContainer li {
    list-style: none;
    padding-bottom: 2rem;
  }

  .MobileLinksContainer a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #fff;
  }
}

/* medium devices */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .mobilenav {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 7%;
    width: 100%;
    background-color: var(--navbar-color);
    z-index: 100;
    border-bottom: 4px solid var(--active-color);
    transition: all 0.5s ease-in-out;
  }

  .open {
    height: 60%;
  }

  .MenuButtonContainer {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    height: auto;
    padding: 1.3rem 1rem 0 0;
  }

  .nav {
    display: none;
  }

  .sticky {
    position: fixed;
    top: 0;
  }

  .Inactive {
    display: none;
  }
  .NavLinks li {
    display: none;
  }
  .MenuButton {
    display: flex;
  }

  .MenuButton button {
    border: none;
    background: none;
  }

  .MobileLinksContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 3rem 0 1rem 0;
    opacity: 1;
    transition: opacity 1s;
  }

  .Closed {
    height: 0px;
    width: 0px;
    opacity: 0;
  }

  .MobileLinksContainer li {
    list-style: none;
    padding-bottom: 2rem;
  }

  .MobileLinksContainer a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #fff;
  }
}

/* large devices */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
}

/* x-large devices */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
}
