:root {
  --card-hover-color: #162244;
  --alt-card-hover-color: #eb068b;
  --alt-card-hoasdf: #616061;
}

/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.AboutSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--plain-background);
}

.AboutTitle {
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 100%;
  align-items: center;
  padding-top: 5rem;
}

.AboutTitle span {
  font-size: 3rem;
  font-weight: bold;
}

.AboutCards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  width: 90%;
  height: 50%;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  border-top: 5px solid var(--alt-card-hover-color);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  background: linear-gradient(
      to bottom,
      var(--alt-card-hover-color) 50%,
      #fff 50%
    )
    bottom;
  background-size: 100% 200%;
  transition: 0.5s ease-out;
}

.Card:nth-child(odd) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  border-top: 5px solid var(--card-hover-color);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  background: linear-gradient(to bottom, var(--card-hover-color) 50%, #fff 50%)
    bottom;
  background-size: 100% 200%;
  transition: 0.5s ease-out;
}

.Card h1 {
  margin-top: 1rem;
  padding-bottom: 2rem;
}

.Card p {
  width: 75%;
}

.Card:hover {
  color: white;
  background-position: top;
}

.IconContainer {
  padding-bottom: 2rem;
}

#aboutnavtrigger {
  position: absolute;
  bottom: 3rem;
  position: absolute;
  top: 15rem;
  height: 10px;
  width: 100px;
}

#stickynavtrigger {
  position: absolute;
  bottom: 3rem;
  position: absolute;
  height: 10px;
  width: 100px;
}

/* x-small devices */
@media screen and (min-width: 100px) and (max-width: 575.98px) {
  .AboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: var(--plain-background);
  }
  .AboutCards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5rem;
    width: 90%;
    height: auto;
  }

  .Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border-top: 5px solid var(--alt-card-hover-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    background: none;
    background-size: 100%;
    transition: none;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .Card:nth-child(odd) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    border-top: 5px solid var(--card-hover-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    background: none;
    background-size: 100%;
    transition: none;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .IconContainer {
    display: none;
  }

  .Card:hover {
    color: black;
  }
}

/* small devices */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .AboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: var(--plain-background);
  }
  .AboutCards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5rem;
    width: 90%;
    height: auto;
  }

  .Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: auto;
    border-top: 5px solid var(--alt-card-hover-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    background: none;
    background-size: 100%;
    transition: none;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .Card:nth-child(odd) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: auto;
    border-top: 5px solid var(--card-hover-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    background: none;
    background-size: 100%;
    transition: none;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .IconContainer {
    display: none;
  }

  .Card:hover {
    color: black;
  }
}

/* medium devices */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .AboutSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: var(--plain-background);
  }
  .AboutCards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5rem;
    width: 90%;
    height: auto;
  }

  .Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: auto;
    border-top: 5px solid var(--alt-card-hover-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    background: none;
    background-size: 100%;
    transition: none;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .Card:nth-child(odd) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: auto;
    border-top: 5px solid var(--card-hover-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    background: none;
    background-size: 100%;
    transition: none;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  .IconContainer {
    display: none;
  }

  .Card:hover {
    color: black;
  }
}

/* large devices */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
}

/* x-large devices */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
}
