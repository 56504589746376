:root {
  --background-color: #162244;
  --background-text: #ced9bf;
  --modal-background-color: #364853;
  --modal-background-hover-color: #5b6870;
  --modal-text: #c09576;
  --button-background-color: #1f313d;
  --plain-background: #ffffff;
}

/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.ProjectsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--plain-background);
}

.ProjectsTitle {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 10%;
  width: 100%;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

.ProjectsTitle span {
  font-size: 3rem;
  font-weight: bold;
}

.ProjectsContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 100%;
}

.ProjectCard {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 50%;
  border-top: 5px solid var(--background-color);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  text-decoration: none;
  color: #000000;
}

.ProjectCard:hover {
  cursor: pointer;
}

.ProjectCardContents {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 80%;
}

.ProjectCardContents h2 {
  font-size: 3rem;
}

.ProjectCardContents h4 {
  font-size: 1.5rem;
}

.ExpandIcon {
  position: absolute;
  background-color: #ffffff;
  top: 2rem;
  right: 2rem;
}

button {
  border: none;
  background-color: #ffffff;
}

button:hover {
  cursor: pointer;
}

#projectnavtrigger {
  position: absolute;
  top: 20rem;
}

/* x-small devices */
@media screen and (min-width: 100px) and (max-width: 575.98px) {
  .ProjectsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: var(--plain-background);
  }

  .ProjectsTitle {
    padding: 4rem 0 4rem 0;
  }

  .ProjectsContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .ProjectCard {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    border-top: 5px solid var(--background-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
    overflow: hidden;
  }

  .ProjectCardHeader {
    padding-top: 4.5rem;
  }

  .ProjectCardContents h4 {
    padding: 1rem 0 4.5rem 0;
    width: 90%;
    line-height: 150%;
  }

  .ExpandIcon {
    display: none;
  }
}

/* small devices */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .ProjectsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: var(--plain-background);
  }

  .ProjectsTitle {
    padding: 4rem 0 4rem 0;
  }

  .ProjectsContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .ProjectCard {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: auto;
    border-top: 5px solid var(--background-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
    overflow: hidden;
  }

  .ProjectCardHeader {
    padding-top: 4.5rem;
  }

  .ProjectCardContents h4 {
    padding: 1rem 0 4.5rem 0;
    width: 90%;
    line-height: 150%;
  }

  .ExpandIcon {
    display: none;
  }
}

/* medium devices */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .ProjectsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: var(--plain-background);
  }

  .ProjectsTitle {
    padding: 4rem 0 4rem 0;
  }

  .ProjectsContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .ProjectCard {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: auto;
    border-top: 5px solid var(--background-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
    overflow: hidden;
  }

  .ProjectCardHeader {
    padding-top: 4.5rem;
  }

  .ProjectCardContents h4 {
    padding: 1rem 0 4.5rem 0;
    width: 90%;
    line-height: 150%;
  }

  .ExpandIcon {
    display: none;
  }
}

/* large devices */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
}

/* x-large devices */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
}
