:root {
  --background-color: #162244;
  --active-color: #eb068b;
  --backup-color: #263459;
}

/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.ContactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--background-color);
  color: white;
}

.ContactTitle {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 100%;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 7rem;
}

.ContactTitle span {
  font-size: 3rem;
  font-weight: bold;
}

.ContactContent {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.ContactForm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  width: 100%;
  padding-bottom: 2rem;
}

.FormInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.FormInput label {
  margin-top: 2rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.FormInput input {
  width: 100%;
  background-color: #f5f5eb;
  padding: 0.5rem 0 0.5rem 0;
  outline: none;
}

.BodyInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.BodyInputContainer label {
  margin-top: 2rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.BodyInput {
  width: 100%;
  min-height: 10rem;
  padding: 0.5rem 0 0.5rem 0;
  background-color: #f5f5eb;
  resize: vertical;
  outline: none;
}

.Focused {
  border: 2px solid var(--active-color);
}

.SubmitButton {
  margin-top: 2rem;
  width: 25%;
  padding: 1rem 0 1rem 0;
}

.SubmitButton:hover {
  cursor: pointer;
}

.ContactIcons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20%;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.ContactIcons a {
  color: white;
  text-decoration: none;
}

.ContactIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25%;
}

.ContactIcon:hover {
  cursor: pointer;
}

#contactnavtrigger {
  position: absolute;
  top: 12rem;
}

/* x-small devices */
@media screen and (min-width: 100px) and (max-width: 575.98px) {
  .ContactContent {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ContactTitle {
    padding-bottom: 1rem;
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .BodyInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .BodyInput {
    width: 100%;
    min-height: 10rem;
    padding: 0.5rem 0 0.5rem 0;
    background-color: #f5f5eb;
    resize: vertical;
    outline: none;
  }

  .SubmitButton {
    margin-top: 2rem;
    width: 50%;
    padding: 1rem 0 1rem 0;
  }
  .ContactIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
}

/* small devices */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .ContactContent {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ContactTitle {
    padding-bottom: 1rem;
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .BodyInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .BodyInput {
    width: 100%;
    min-height: 10rem;
    padding: 0.5rem 0 0.5rem 0;
    background-color: #f5f5eb;
    resize: vertical;
    outline: none;
  }

  .SubmitButton {
    margin-top: 2rem;
    width: 50%;
    padding: 1rem 0 1rem 0;
  }
  .ContactIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
}

/* medium devices */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .ContactContent {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ContactTitle {
    padding-bottom: 1rem;
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .BodyInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
  }

  .BodyInput {
    width: 100%;
    min-height: 10rem;
    padding: 0.5rem 0 0.5rem 0;
    background-color: #f5f5eb;
    resize: vertical;
    outline: none;
  }

  .SubmitButton {
    margin-top: 2rem;
    width: 50%;
    padding: 1rem 0 1rem 0;
  }
  .ContactIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
}

/* large devices */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
}

/* x-large devices */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
}
