:root {
}

/* reset */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#tsparticles {
  z-index: -10;
}

.HeroSection {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.HeroContent {
  padding-top: 20rem;
}

.HeroDesc {
  padding-bottom: 20rem;
}

.HeroDesc h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.HeroDesc p {
  font-size: 1.25rem;
}

.Bounce a {
  text-decoration: none;
  color: #fff;
}

.Bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

#heronavtrigger {
  position: absolute;
  bottom: 1rem;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* x-small devices */
@media screen and (min-width: 100px) and (max-width: 575.98px) {
  .Bounce {
    display: none;
  }
}

/* small devices */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .Bounce {
    display: none;
  }
}

/* medium devices */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .Bounce {
    display: none;
  }
}

/* large devices */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
}

/* x-large devices */
@media screen and (min-width: 1200px) and (max-width: 1399.98px) {
}
